/* eslint-disable security/detect-object-injection */
/* eslint-disable prefer-destructuring */
import { ISelectItem, ModalContainerService } from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  RFIItemService,
  IPRFIListItem,
  IUser,
} from "src/app/api-generated";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import RFIRole from "src/app/models/rfiRoles";
import LoadingService from "src/app/services/loading.service";
import LocalContractService from "src/app/services/local-contract.service";
import RFIListRowItem from "src/app/models/rfiListViewModel";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import StatusList from "src/app/models/statusList";
import LocalSpecificationService from "src/app/services/local-specification.service";
import LocalClassificationService from "src/app/services/local-classification.service";
import LocalPhaseService from "src/app/services/local-phase.service";
import LocalUserService from "src/app/services/local-user.service";
import { isFacOrTsbContract, isOldContract } from "src/app/shared/utils";
import AuthService from "../../auth/auth.service";
import LocalApplicationRoleService from "@services/local-application_role.service";
import RFIStatus from "@models/rfiStatus";

@Component({
  selector: "app-rfilist",
  templateUrl: "./rfi-list.component.html",
  styleUrls: ["./rfi-list.component.scss"],
})
export default class RFIListComponent implements OnInit {
  @HostListener("window:beforeprint", ["$event"])
  // eslint-disable-next-line class-methods-use-this
  onBeforePrint(event): void {
    this.fixedheadercss = false;
  }

  @HostListener("window:afterprint", ["$event"])
  // eslint-disable-next-line class-methods-use-this
  onAfterePrint(event): void {
    this.fixedheadercss = true;
  }

  subtitle: string;

  contractId: string;

  contractCode: string;

  isDBAdmin: boolean;

  isDoc: boolean;

  statusFilters: StatusList[] = [];

  userList: IUser[];

  tableRows: RFIListRowItem[] = [];

  tableData: RFIListRowItem[] = [];

  fixedheadercss = true;

  statusDropdownFilter: ISelectItem[] = [
    {
      checked: false,
      id: "default",
      name: "All RFIs",
    },
    {
      checked: false,
      id: "open",
      name: "Open RFIs",
    },
    {
      checked: false,
      id: "closed",
      name: "Closed RFIs",
    },
  ];

  phaseDropdownFilter: ISelectItem[];

  classificationDropdownFilter: ISelectItem[];

  stepDropdownFilter: ISelectItem[];

  IsOldContract = false;

  phaseFilterId: string = null;

  classificationFilterId: string = null;

  stepFilterId: string = null;

  statusFilterId: string = null;

  searchText: string;

  IsFacOrTsbContract = false;

  widthPriority = 100;
  widthId = 170;
  widthDate = 120;
  widthAction = 150;
  widthPhase = 100;
  widthAlt = 50;

  showAltUrl = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public rfiItemService: RFIItemService,
    public activeModal: ModalContainerService,
    public localContractService: LocalContractService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public authService: AuthService,
    public localSpecificationService: LocalSpecificationService,
    public localClassificationService: LocalClassificationService,
    public localPhaseService: LocalPhaseService,
    public localApplicationRoleService: LocalApplicationRoleService,
  ) {}

  ngOnInit(): void {
    const data: IPRFIListItem[] = this.activatedRoute.snapshot.data.RFIList;
    const role = this.localContractUserService.currentUserContractRole;
    const users = this.localUserService.getItems();
    this.contractId = this.localContractService.currentContract.Guid;
    this.contractCode = this.localContractService.currentContract.Code;
    this.IsFacOrTsbContract = isFacOrTsbContract(this.contractCode);
    this.statusFilters = RFIListComponent.getStatusFilters(role);
    this.subtitle = RFIListComponent.getUserTitle(role);
    this.isDBAdmin = role === RFIRole.DBAdmin;
    // this.isDBAdmin = false;
    this.isDoc = role === RFIRole.Doc_Control;
    const largest = new Date("12/31/2100").getTime();

    this.tableData = data
      .sort(
        (a, b) =>
          (b.IssueDate ? new Date(b.IssueDate).getTime() : largest) -
          (a.IssueDate ? new Date(a.IssueDate).getTime() : largest),
      )
      .map((item) => {
        return new RFIListRowItem(
          this.localApplicationRoleService,
          this.contractId,
          this.authService.user.Id,
          item,
          role,
          users,
        );
      });
    this.tableData.sort((a, b) => {
      return b.initSort - a.initSort;
    });
    this.showAltUrl = this.tableData.find((r)=>{return r.altUrl }) !== undefined;
    // console.log(this.tableData);
    this.loadingService.stop();

    this.classificationDropdownFilter = this.localClassificationService
      .getItems()
      .filter((item) => {
        return item.InActive !== true;
      })
      .map((item) => {
        return {
          name: item.Name,
          id: item.Name,
          checked: false,
        };
      });
    this.classificationDropdownFilter.unshift({
      checked: true,
      id: "default",
      name: "All Classifications",
    });
    this.phaseDropdownFilter = this.localPhaseService.getItems().map((item) => {
      return {
        name: item.Title,
        id: item.Guid,
        checked: false,
      };
    });
    this.phaseDropdownFilter.unshift({
      checked: true,
      id: "default",
      name: "All Phases",
    });
    this.stepDropdownFilter = [...new Set(this.tableData.map((item) => item.statusText))].map((step)=>{
      return {
        name: step,
        id: step,
        checked: false,
      };
    }).sort((a,b)=>a.id.localeCompare(b.id));
    this.stepDropdownFilter.unshift({
      checked: true,
      id: "default",
      name: "All Steps",
    });
    // determine if it's a old/new contract
    this.IsOldContract = isOldContract(
      this.localContractService.getItem().Code,
    );
  }

  createRFI(): void {
    this.loadingService.start();
    this.router.navigateByUrl(`${this.contractId}/rfi/new`);
  }

  goTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  filterData() {
    const searchVar = this.searchText.trim().toLowerCase().split(" ");
    this.tableData.forEach((i) => {
      const searchableValue =
        `${i.priority},${i.rfiNumber},${i.rfiTitle},${i.phase}_${i.classification},${i.specification},${i.ballInCourt},${i.status}`.toLowerCase();
      i.isHidden = !searchVar.every((term) => {
        return searchableValue.includes(term);
      });
    });
    this.statusFilterId = null;
    this.phaseFilterId = null;
    this.classificationFilterId = null;
    this.stepFilterId = null;
  }

  changeStatusFilter(e: ISelectItem): void {
    const closeStatusArr: string[] = [RFIStatus.Canceled, RFIStatus.Responded, RFIStatus.Superseded];
    if (!Array.isArray(e)) {
      switch (e.id) {
        case "open":
          this.tableData.forEach((i) => {
            i.isHidden = closeStatusArr.includes(i.status);
          });
          break;
        case "closed":
          this.tableData.forEach((i) => {
            i.isHidden = !closeStatusArr.includes(i.status);
          });
          break;
        default:
          this.tableData.forEach((i) => {
            i.isHidden = false;
          });
          break;
      }
      this.searchText = "";
      this.phaseFilterId = null;
      this.classificationFilterId = null;
      this.stepFilterId = null;
      this.updateTable();
    }
  }

  changePhaseFilter(e: ISelectItem): void {
    if (!Array.isArray(e)) {
      const selectedItem = e.name.toLowerCase();
      if (e.id && e.id != null && e.id !== "default") {
        this.tableData.forEach((i) => {
          const phase = i.phase.toLowerCase();
          i.isHidden = phase !== selectedItem;
        });
      } else {
        this.tableData.forEach((i) => {
          i.isHidden = false;
        });
      }
      this.searchText = "";
      this.statusFilterId = null;
      this.classificationFilterId = null;
      this.stepFilterId = null;
      this.updateTable();
    }
  }

  changeClassificationFilter(e: ISelectItem): void {
    if (!Array.isArray(e)) {
      console.log(e);

      if (e.id && e.id != null && e.id !== "default") {
        this.tableData.forEach((i) => {
          i.isHidden = i.classification !== e.id;
        });
      } else {
        this.tableData.forEach((i) => {
          i.isHidden = false;
        });
      }

      this.searchText = "";
      this.statusFilterId = null;
      this.phaseFilterId = null;
      this.stepFilterId = null;
      this.updateTable();
    }
  }

  changeStepFilter(e: ISelectItem): void {
    if (!Array.isArray(e)) {
      console.log(e);

      if (e.id && e.id != null && e.id !== "default") {
        this.tableData.forEach((i) => {
          i.isHidden = i.statusText !== e.id;
        });
      } else {
        this.tableData.forEach((i) => {
          i.isHidden = false;
        });
      }

      this.searchText = "";
      this.statusFilterId = null;
      this.phaseFilterId = null;
      this.classificationFilterId = null;
      this.updateTable();
    }
  }

  toggleStatus(index: number): void {
    this.statusFilters[index].active = !this.statusFilters[index].active;

    this.tableData.forEach((row) => {
      if (
        row.status.toLowerCase() ===
        this.statusFilters[index].name.toLowerCase()
      ) {
        row.isHidden = !this.statusFilters[index].active;
      }
    });
  }

  updateTable(): void {
    this.tableData.forEach((item) => {
      this.statusFilters.forEach((filterItem) => {
        if (
          item.status.toLowerCase() === filterItem.name.toLowerCase() &&
          !filterItem.active
        ) {
          item.isHidden = true;
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getDate(date: string): string {
    if (date === "-") return date;

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions;

    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
    return tempDate
      .toLocaleDateString(undefined, options)
      .replace(", ", "-")
      .replace(" ", "-");
  }

  // eslint-disable-next-line class-methods-use-this
  getFormattedDate(date: string): string | Date {
    if (date === "-") return date;
    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
    const year = tempDate.getFullYear();
    const month = (1 + tempDate.getMonth()).toString().padStart(2, "0");
    const day = tempDate.getDate().toString().padStart(2, "0");

    return `${month}/${day}/${year}`;
  }

  getLogFormattedDate(date: string): string | Date {
    if (date === "-") return date;
    return new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
    // const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
    // const year = tempDate.getFullYear();
    // const month = (1 + tempDate.getMonth()).toString().padStart(2, "0");
    // const day = tempDate.getDate().toString().padStart(2, "0");

    // return `${month}/${day}/${year}`;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  download(): void {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions;
    const now = new Date()
      .toLocaleDateString(undefined, options)
      .replace(", ", "-")
      .replace(" ", "-");
    const fileName = `BBJ-${this.contractCode ?? ""}-RFI-${now}.xlsx`;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Logs");
    worksheet.addTable({
      name: "MyTable",
      ref: "A1",
      headerRow: true,
      style: {
        theme: "TableStyleMedium4",
        showRowStripes: true,
      },
      columns: this.isDBAdmin
        ? [
            { name: "Priority", filterButton: true },
            { name: "RFI #", filterButton: true },
            { name: "RFI Title", filterButton: true },
            { name: "Questions", filterButton: true },
            { name: "Response", filterButton: true },
            { name: "Division", filterButton: true },
            { name: "Specification", filterButton: true },
            { name: "Classification", filterButton: true },
            { name: "Contract Requirements", filterButton: true },
            { name: "Phase", filterButton: true },
            { name: "Anticipated Schedule Impact", filterButton: true },
            { name: "Anticipated Cost Impact", filterButton: true },
            { name: "Processing Notes", filterButton: true },
            { name: "Master Specification", filterButton: true },
            { name: "Issue Date", filterButton: true },
            { name: "Due Date", filterButton: true },
            { name: "Response Date", filterButton: true },
            { name: "BIC (Name & Email)", filterButton: true },
            { name: "Status", filterButton: true },
            { name: "Reference URL", filterButton: true },
            { name: "Contract Ref #", filterButton: true },
          ]
        : [
            { name: "Priority", filterButton: true },
            { name: "RFI #", filterButton: true },
            { name: "RFI Title", filterButton: true },
            { name: "Questions", filterButton: true },
            { name: "Response", filterButton: true },
            { name: "Division", filterButton: true },
            { name: "Specification", filterButton: true },
            { name: "Classification", filterButton: true },
            { name: "Contract Requirements", filterButton: true },
            { name: "Phase", filterButton: true },
            { name: "Anticipated Schedule Impact", filterButton: true },
            { name: "Anticipated Cost Impact", filterButton: true },
            { name: "Processing Notes", filterButton: true },
            { name: "Master Specification", filterButton: true },
            { name: "Issue Date", filterButton: true },
            { name: "Internal Due", filterButton: true },
            { name: "Due Date", filterButton: true },
            { name: "Response Date", filterButton: true },
            { name: "BIC (Name & Email)", filterButton: true },
            { name: "PMC Manager", filterButton: true },
            { name: "Coordinator", filterButton: true },
            { name: "DDC Manager", filterButton: true },
            { name: "Status", filterButton: true },
            { name: "Reference URL", filterButton: true },
            { name: "Contract Ref #", filterButton: true },
            { name: "Reviewers", filterButton: true },
            { name: 'Schedule Impact (Internal)', filterButton: true },
            { name: 'Cost Impact (Internal)', filterButton: true },
          ],
      rows: this.tableRows.map((d) => {
        if(d.rfiNumber === "BK-PRO-RFI-00021")
          {
            console.log(d.answer);
          }
        
        let priority = "Low";
        switch (d.priority) {
          case 2:
            priority = "Medium";
            break;
          case 3:
            priority = "High";
            break;
          default:
            priority = "Low";
            break;
        }
        return this.isDBAdmin
          ? [
              priority,
              d.rfiNumber,
              d.rfiTitle,
              d.question.replace("\n","\r\n"),
              d.answer.replace("\n","\r\n"),
              d.division,
              d.specification,
              d.classification,
              d.contractRequirement,
              d.phase,
              d.anticipatedScheduleImpact,
              d.anticipatedCostImpact,
              d.processingNotes,
              d.masterSpec,
              this.getLogFormattedDate(d.issuedOn),
              this.getLogFormattedDate(d.dueOn),
              this.getLogFormattedDate(d.reponseDate),
              d.ballInCourtInfo
                ? `${d.ballInCourtInfo?.displayName ?? ""}\r\n${
                    d.ballInCourtInfo?.mail ?? ""
                  }`
                : d.ballInCourt,
              d.statusText,
              d.url,
              d.ref,
            ]
          : [
              priority,
              d.rfiNumber,
              d.rfiTitle,
              d.question,
              d.answer,
              d.division,
              d.specification,
              d.classification,
              d.contractRequirement,
              d.phase,
              d.anticipatedScheduleImpact,
              d.anticipatedCostImpact,
              d.processingNotes,
              d.masterSpec,
              this.getLogFormattedDate(d.issuedOn),
              this.getLogFormattedDate(d.internalDueOn),
              this.getLogFormattedDate(d.dueOn),
              this.getLogFormattedDate(d.reponseDate),
              d.ballInCourtInfo
                ? `${d.ballInCourtInfo?.displayName ?? ""}\r\n${
                    d.ballInCourtInfo?.mail ?? ""
                  }`
                : d.ballInCourt,
              d.manager,
              d.coordinator,
              d.ddcManager,
              d.statusText,
              d.url,
              d.ref,
              d.reviewers.join("\r\n"),
              d.scheduleImpact??'',
              d.costImpact??''
            ];
      }),
    });
    worksheet.getColumn(4).alignment = { wrapText: true };
    worksheet.getColumn(5).alignment = { wrapText: true };
    if (this.isDBAdmin) {
      worksheet.getColumn(18).alignment = { wrapText: true };
    } else {
      worksheet.getColumn(19).alignment = { wrapText: true };
      worksheet.getColumn(20).alignment = { wrapText: true };
      worksheet.getColumn(21).alignment = { wrapText: true };
      worksheet.getColumn(22).alignment = { wrapText: true };
      worksheet.getColumn(26).alignment = { wrapText: true };
    }

    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength > 20 ? 20 : maxLength;
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, fileName);
    });
  }

  private static getStatusFilters(userRole: string): StatusList[] {
    const statusFullList: StatusList[] = [
      {
        name: "New Submittal",
        icon: "icon-lg icon-action_new-submittal",
        active: true,
        isHidden: true,
      },
      {
        name: "Draft",
        icon: "icon-lg icon-action_write",
        active: true,
        isHidden: true,
      },
      {
        name: "Submitted",
        icon: "icon-lg icon-action_rotate_anticlockwise",
        active: true,
        isHidden: true,
      },
      {
        name: "Distribution",
        icon: "icon-lg icon-action_rotate_anticlockwise",
        active: true,
        isHidden: true,
      },
      {
        name: "Review Period",
        icon: "icon-lg icon-action_combine ",
        active: true,
        isHidden: true,
      },
      {
        name: "PM Review",
        icon: "icon-lg icon-action_check_circle",
        active: true,
        isHidden: true,
      },
      {
        name: "SPM Review",
        icon: "icon-lg icon-action_check_circle",
        active: true,
        isHidden: true,
      },
      {
        name: "DDC Review",
        icon: "icon-lg icon-action_check_circle",
        active: true,
        isHidden: true,
      },
      {
        name: "QAQC Fail",
        icon: "icon-lg icon-action_ban",
        active: true,
        isHidden: true,
      },
      {
        name: "Canceled",
        icon: "icon-lg icon-action_remove_circle",
        active: true,
        isHidden: true,
      },
      {
        name: "Responded",
        icon: "icon-lg icon-action_special",
        active: true,
        isHidden: true,
      },
      {
        name: "Superseded",
        icon: "icon-lg icon-superseded",
        active: true,
        isHidden: true,
      },
      {
        name: "Recalled",
        icon: "icon-lg icon-action_remove_circle",
        active: true,
        isHidden: true,
      },
    ];

    switch (userRole) {
      case "DB Admin":
        statusFullList[1].isHidden = false;
        statusFullList[2].isHidden = false;
        statusFullList[7].isHidden = false;
        statusFullList[8].isHidden = false;
        statusFullList[9].isHidden = false;
        statusFullList[10].isHidden = false;
        return statusFullList.filter((i) => !i.isHidden);
      case "PMC Doc Control":
        statusFullList[1].isHidden = false;
        statusFullList[2].isHidden = false;
        statusFullList[3].isHidden = false;
        statusFullList[4].isHidden = false;
        statusFullList[6].isHidden = false;
        statusFullList[7].isHidden = false;
        statusFullList[8].isHidden = false;
        statusFullList[9].isHidden = false;
        statusFullList[10].isHidden = false;
        return statusFullList.filter((i) => !i.isHidden);
      default:
        statusFullList[2].isHidden = false;
        statusFullList[3].isHidden = false;
        statusFullList[4].isHidden = false;
        statusFullList[6].isHidden = false;
        statusFullList[7].isHidden = false;
        statusFullList[8].isHidden = false;
        statusFullList[9].isHidden = false;
        statusFullList[10].isHidden = false;
        return statusFullList.filter((i) => !i.isHidden);
    }
  }

  private static getUserTitle(userRole: string): string {
    switch (userRole) {
      case RFIRole.DBAdmin:
        return "Design Builder | User";
      case RFIRole.Doc_Control:
        return "Doc Control";
      case RFIRole.Manager:
        return "Project Manager";
      case RFIRole.SR_Manager:
        return "Sr. Project Manager";
      case RFIRole.Coordinator:
        return "Coordinator";
      case RFIRole.DDC_Manager:
        return "DDC Manager";
      default:
        return "User";
    }
  }
}
