/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable import/no-extraneous-dependencies */
import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import RowItem from "../content/selectUser/table-row";
import IContractUserWithUserInfo from "../models/IContractUserWithUserInfo";
import RFIRole from "../models/rfiRoles";
import LoadingService from "../services/loading.service";
import {
  rfiAppId,
  rfiDbRoleId,
} from "./staticValue";
import { IPRFIDocumentDownload, IPRFIItem, IPRFIListItem, IUser, RfiCompiledResponse, RfiDuedate } from "../api-generated";
import { isEmpty } from "lodash";
import RFIStatus from "@models/rfiStatus";
import _ from "lodash";
import environment from "src/environments/environment";
import OptionsCostImpact from "@models/OptionsCostImpact";
import OptionsScheduleImpact from "@models/OptionsScheduleImpact";


export function getDateWithOffset(date: string): Date {
  return new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
}

export function isFileNameInvalid(name: string): boolean {
  const specialChars = /[`!#$%^&*+=\[\]{};':"\\|,<>\/?~]/;
  return specialChars.test(name);
}

export function getDateFormatted_MMDDYYYY(date: Date | string): string {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (1 + d.getMonth()).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
}

export function getFormattedDate(date: Date | string): string {
  const d = new Date(date);
  const datetimestr = d.toLocaleTimeString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return datetimestr;
}

export function getDifferenceInDays(startdate, enddate) {
  const date1 = new Date(startdate);
  const date2 = new Date(enddate);
  const timeInMilisec: number = date1.getTime() - date2.getTime();
  return Math.floor(timeInMilisec / (1000 * 60 * 60 * 24));
}

export function getUserName(user: IUser): string {
  let firstName = user.givenName;
  let lastName = user.surname;

  if (user.surname === null || user.givenName == null) {
    if (user.displayName.includes(",")) {
      const fullName = user.displayName.split(", ");

      [lastName, firstName] = fullName;
    } else {
      const email = user.mail.split("@")[0];
      const fullName = email.split(".");

      if (email.includes(".")) {
        [firstName, lastName] = fullName;
      } else {
        firstName = "??";
        lastName = "??";
      }
    }
  }

  return `${firstName} ${lastName}`;
}

export function getUserNameCompanyDepartment(user: IUser): string {
  return user ?  `${user.displayName??''}, ${user.companyName??''}/${user.department??''}`: null;
}

export function generateUserRowItem<T>(
  item: T & { UserInfo?: IUser },
  extraProperties?: any
): RowItem {
  let organization = "";

  if (item.UserInfo?.department) {
    const nameArray = item.UserInfo.department.split(" ");

    if (nameArray) {
      [organization] = nameArray;
    }
  }
  return {
    id: item.UserInfo.id,
    name: item.UserInfo.displayName ?? "",
    email: item.UserInfo.mail ?? "",
    organization,
    track: item.UserInfo.department ?? "",
    checked: false,
    ...extraProperties,
  };
}

export function combineUserInfo<T>(
  item: T,
  user?: IUser
): T & { UserInfo?: IUser } {
  return { ...item, UserInfo: user };
}

export function getUsersDropDownByRoleId(
  users: IContractUserWithUserInfo[],
  roleId: string
): ISelectItem[] {
  return users
    .filter((u) => {
      return u.contract_user_application_role.find((a) => {
        return a.ApplicationId === rfiAppId && a.ApplicationRoleId === roleId;
      });
    })
    .map((u) => {
      return {
        id: u.UserId,
        name: u.UserInfo.displayName ?? "",
        checked: false,
      };
    });
}

export function getAllUsersExceptDB(
  users: IContractUserWithUserInfo[]
): RowItem[] {
  return users
    .filter((u) => {
      return u.contract_user_application_role.find((a) => {
        return (
          a.ApplicationId === rfiAppId && a.ApplicationRoleId !== rfiDbRoleId
        );
      });
    })
    .map((user) => {
      const rfiRole = user.contract_user_application_role.filter((role) => {
        return role.ApplicationId === rfiAppId;
      });
      const role =
        rfiRole.length > 0 ? rfiRole[0].application_role.Role : "User";
      return generateUserRowItem<IContractUserWithUserInfo>(user, {
        role,
      });
    });
}

export function errorHandle(
  error: HttpErrorResponse,
  activeModal: ModalContainerService,
  router: Router,
  loadingService: LoadingService
): void {
  loadingService.stop();
  if (error.status === 403 || error.status === 409) {
    const modalInstance1 = activeModal.open(NotificationComponent);
    modalInstance1.instance.type = NotificationType.Information;
    modalInstance1.instance.theme = "light";
    modalInstance1.instance.title = "Your Changes Can't be Saved";
    modalInstance1.instance.body =
      "Someone already saved changes. Please refresh your screen to see updates.";
    modalInstance1.result.then((result1) => {
      if (result1 === 0) {
        const currentUrl = router.url;
        router.navigateByUrl("blank").then(() => {
          router.navigateByUrl(currentUrl);
        });
      }
    });
  } else {
    console.log(error);
    router.navigateByUrl(`${this.contractId}/list`);
  }
}

export function isOldContract(contractCode: string): boolean {
  let resp = false;
  const oldContracts = ['dss', 'gar', 'twm', 'spc'];
  oldContracts.forEach((oldCode: string) => {
    if (contractCode.toLocaleLowerCase().endsWith(oldCode) === true) {
      resp = true;
    };
  });
  return resp;
}

export function isFacOrTsbContract(contractCode: string): boolean {
  return ['fac', 'tsb'].some((code)=>{
    return contractCode.toLocaleLowerCase().endsWith(code);
  });
}

export function isGARContract(contractCode: string): boolean {
  let resp = false;
  if (contractCode.toLocaleLowerCase().endsWith('gar') === true) {
    resp = true;
  };
  return resp;
}

export function isValidUUID(id: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
}

export function prepareMutiLineText(text: string | null | undefined): string {
  return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
}

export function getBusinessDatesCount(startDate: string, endDate: string) {
  let count = 0;
  const start = new Date(new Date(new Date(startDate).toLocaleDateString()).getTime());
  const end = new Date(new Date(new Date(endDate).toLocaleDateString()).getTime());
  while (start < end) {
      const dayOfWeek = start.getDay();
      if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      start.setDate(start.getDate() + 1);
  }
  return count;
}

export function getDateWithOffSet(date: string): string {
  const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
  return tempDate.toLocaleDateString();
}

export function getFixTimeDate(a: string): string {
  if (!a) return null;

  return getDateWithOffSet(a) + " 04:00 PM EST";
}

export function getAccTimeDate(a: string): string {
  if (!a) return null;
  const options = { hour: "2-digit", minute: "2-digit", hour12: true } as Intl.DateTimeFormatOptions;
  return `${getDateWithOffSet(a)} ${new Date(a).toLocaleTimeString("en-US", options)} EST`;
}

export function shouldReview(rfiItem: IPRFIItem, userId: string): boolean {
  let needReview = false;
  if (rfiItem.rfi_reviewer && rfiItem.rfi_reviewer.length > 0) {
    const reviewer = rfiItem.rfi_reviewer.find((r) => {
      return r.ReviewerId === userId && !r.DeletedBy;
    });
    if(reviewer?.hasComments !== false && (!reviewer.Response || isEmpty(reviewer.Response)))
    {
      needReview = true;
    }
  }
  return needReview;
}

export function canEditRFI(
  rfi: IPRFIItem,
  role: string,
  userId: string,
  isReviewer: boolean,
): boolean {
  if (rfi.IsRecalled) {
    return role === RFIRole.Doc_Control;
  } else if(role === RFIRole.DBAdmin) {
    return rfi.Status === RFIStatus.Draft || rfi.Status === RFIStatus.QAQC_Fail;
  } else {
    switch (rfi.Status) {
      case RFIStatus.Draft:
      case RFIStatus.QAQC_Fail:
        return role === RFIRole.DBAdmin ||
              role === RFIRole.Doc_Control;
      case RFIStatus.Submitted:
        return role === RFIRole.Doc_Control;
      case RFIStatus.Distribution:
        return rfi.CoordinatorId === userId;
      case RFIStatus.Review_Period:
        return rfi.CoordinatorId === userId || isReviewer;
      case RFIStatus.PM_Review:
        return rfi.ManagerId === userId;
      case RFIStatus.SPM_Review:
        return rfi.SrManagerId === userId;
      case RFIStatus.DDC_Review:
        return rfi.DDCManagerId === userId;
      default:
        return false;
    }
  }
}

export function getRFIlink(
  contractId: string,
  rfiItem: IPRFIItem,
  viewOnly: boolean,
  isReviewer: boolean,
): string {
  if (viewOnly || rfiItem.IsRecalled) {
    return `/${contractId}/view/${rfiItem.Guid}`;
  }
  else {
    switch (rfiItem.Status) {
      case RFIStatus.Draft:
        return `/${contractId}/create/${rfiItem.Guid}`;
      case RFIStatus.Submitted:
      case RFIStatus.Distribution:
        return `/${contractId}/distribution/${rfiItem.Guid}`;
      case RFIStatus.Review_Period:
        if (isReviewer) {
          return `/${contractId}/reviewRFI/${rfiItem.Guid}`;
        } else {
          return `/${contractId}/compilingResponse/${rfiItem.Guid}`;
        }
      case RFIStatus.PM_Review:
      case RFIStatus.DDC_Review:
      case RFIStatus.SPM_Review:
        return `/${contractId}/compilingResponse/${rfiItem.Guid}`;
      default:
        return `/${contractId}/view/${rfiItem.Guid}`;
    }
  }
}


export function isReviewer(rfiItem: IPRFIItem, userId: string): boolean {
  if (rfiItem.rfi_reviewer && rfiItem.rfi_reviewer.length > 0) {
    const reviewer = rfiItem.rfi_reviewer.find((r) => {
      return r.ReviewerId === userId && !r.DeletedBy;
    });
    if (reviewer) {
      return true;
    }
  }
  return false;
}

export function isReviseDraft(rfi: IPRFIItem): boolean {
  if(rfi.Status === RFIStatus.Draft) {
    const flowArray = rfi.rfi_flow.sort(
      (a, b) =>
        new Date(b.DateReceived).getTime() -
        new Date(a.DateReceived).getTime(),
    );
    let i = 0;
    while (i < flowArray.length && flowArray[i].Status === RFIStatus.Draft)
    {
      i++;
    }
    return i < flowArray.length && flowArray[i].Status === RFIStatus.QAQC_Fail;
  }
  return false;
}

export function getStatusText(rfi: IPRFIItem, isDB: boolean): string {
  if (rfi.IsRecalled) {
    return RFIStatus.Recalled;
  } else if (isDB) {
    const processingStatusArr: string[] = [RFIStatus.Distribution,RFIStatus.Review_Period,RFIStatus.PM_Review,RFIStatus.DDC_Review,RFIStatus.SPM_Review];
    if(processingStatusArr.includes(rfi.Status))
    {
      if(rfi.rfi_reopen?.length>0) {
        return RFIStatus.Reopen_in_Progress;
      } else {
        return RFIStatus.Submitted;
      }
    }
  }
  return rfi.Status;
}

export function getIcon(rfi: IPRFIItem, isDB): string {
  let iconClass = "";
  switch (getStatusText(rfi, isDB)) {
    case RFIStatus.Draft:
      iconClass = "icon-action_write";
      break;
    case RFIStatus.Submitted:
    case RFIStatus.Distribution:
    case RFIStatus.Reopen_in_Progress:
      iconClass = "icon-action-anticlockwise";
      break;
    case RFIStatus.Review_Period:
      iconClass = "icon-action_combine";
      break;
    case RFIStatus.PM_Review:
    case RFIStatus.SPM_Review:
    case RFIStatus.DDC_Review:
      iconClass = "icon-action_check_circle";
      break;
    case RFIStatus.Responded:
      iconClass = "icon-action_special";
      break;
    case RFIStatus.Recalled:
    case RFIStatus.Canceled:
      iconClass = "icon-action_remove_circle";
      break;
    case RFIStatus.QAQC_Fail:
      iconClass = "icon-action_ban";
      break;
    case RFIStatus.Superseded:
      iconClass = "icon-superseded";
      break;
    case RFIStatus.Request_to_Reopen:
      iconClass = "icon-action_re-send";
      break;
    default:
      break;
  }
  return iconClass;
}

export function getDocumentsforFinalResponse(docs: IPRFIDocumentDownload[]): IPRFIDocumentDownload[] {
  const statusArr: string[] = [RFIStatus.PM_Review, RFIStatus.SPM_Review, RFIStatus.DDC_Review];
  return docs.filter((d)=>{return d.ReferenceId === null && statusArr.includes(d.Status) });
}

export function mapDocumentsToFile(docs: IPRFIDocumentDownload[]): FileModel[] {
  const documnetsForThis = docs
      .map((item) => {
        return new FileModel(
          item.Guid,
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL,
        );
      });
  return _.uniqBy(
    documnetsForThis.sort((a, b) => {
      return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
    }),
    "Name",
  );
}

  
export function getWorkflowStatusArray(rfi: IPRFIListItem): string[] {
  if(rfi?.rfi_flow)
  {
    return rfi.rfi_flow.sort(
      (a, b) =>
        new Date(b.DateReceived).getTime() -
        new Date(a.DateReceived).getTime(),
    ).map((f)=>{return f.Status});
  }
  return [];
}

export function shouldShowDocControl(rfi: IPRFIListItem): boolean {
  return shouldShowCoordinator(rfi);
}

export function shouldShowManager(rfi: IPRFIListItem): boolean {
  return getWorkflowStatusArray(rfi).includes(RFIStatus.Review_Period) || isFinalStep(rfi.Status);
}

export function shouldShowAltManager(rfi: IPRFIListItem): boolean {
  const arr = getWorkflowStatusArray(rfi);
  return arr.includes(RFIStatus.SPM_Review) || arr.includes(RFIStatus.SPM_Review) || arr.includes(RFIStatus.DDC_Review) || isFinalStep(rfi.Status);
}

export function shouldShowCoordinator(rfi: IPRFIListItem): boolean {
  return getWorkflowStatusArray(rfi).includes(RFIStatus.Distribution) || isFinalStep(rfi.Status);
}

export function shouldShowSrManager(rfi: IPRFIListItem): boolean {
  const arr = getWorkflowStatusArray(rfi);
  return arr.includes(RFIStatus.DDC_Review) || arr.includes(RFIStatus.SPM_Review) || isFinalStep(rfi.Status);
}

export function shouldShowDDCManager(rfi: IPRFIListItem): boolean {
  return getWorkflowStatusArray(rfi).includes(RFIStatus.DDC_Review) || isFinalStep(rfi.Status);
}

export function shouldShowAltDDCManager(rfi: IPRFIListItem): boolean {
  return isFinalStep(rfi.Status);
}

export function isFinalStep(status: string): boolean {
  switch (status) {
    case RFIStatus.Canceled:
    case RFIStatus.Superseded:
    case RFIStatus.Responded:
    case RFIStatus.DB_Acknowledgment:
      return true;
    default:
      return false;
  }
}

export function isDB(role: string): boolean {
  return role === RFIRole.DBAdmin ||
    (environment.stage === "local" && role === RFIRole.Doc_Control);
}

export function getRoleForRFI(userId: string, rfi: IPRFIItem, users: IContractUserWithUserInfo[], before?: string): string | null {
  const delegateDetails = (before? rfi.rfi_delegate_detail.filter((d)=>{return new Date(d.DateUpdated) <= new Date(before)}) : rfi.rfi_delegate_detail).sort((a,b)=> new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime());
  const coordinatorD = delegateDetails.find((d)=>{return d.RoleId === RFIRole.Coordinator});
  const managerD = delegateDetails.find((d)=>{return d.RoleId === RFIRole.Manager});
  const srManagerD = delegateDetails.find((d)=>{return d.RoleId === RFIRole.SR_Manager});
  const ddcManagerD = delegateDetails.find((d)=>{return d.RoleId === RFIRole.DDC_Manager});
  const coordinator = coordinatorD?.UserId ?? rfi.CoordinatorId;
  const manager = managerD?.UserId ?? rfi.ManagerId;
  const srManager = srManagerD?.UserId ?? rfi.SrManagerId;
  const ddcManager = ddcManagerD?.UserId ?? rfi.DDCManagerId;
  if(userId === coordinator)
  {
    return RFIRole.Coordinator;
  } else if(userId === manager)
  {
    return RFIRole.Manager
  } else if(userId === srManager)
  {
    return RFIRole.SR_Manager
  } else if(userId === ddcManager)
  {
    return RFIRole.DDC_Manager
  } else
  {
    const user = users.find((u)=>{return u.UserId === userId});
    if(user)
    {
      const role = user.contract_user_application_role.find((cr)=>{return cr.ApplicationId === rfiAppId && (cr.ApplicationRoleId === RFIRole.Doc_Control||cr.ApplicationRoleId === RFIRole.DBAdmin)});
      if(role)
      {
        return role.ApplicationRoleId;
      }
    }
  }
  return null;
}

export function getStatusBeforeReopen(item: IPRFIItem): string | undefined {
  const flows = item.rfi_flow.filter((f)=>{return f.Status!==RFIStatus.Request_to_Reopen && f.Status!==RFIStatus.DB_Acknowledgment}).sort((a,b)=>(b.DateReceived?new Date(b.DateReceived).getTime():0)-(a.DateReceived?new Date(a.DateReceived).getTime():0));
  if(flows.length > 0)
  {
    return flows[0].Status;
  }
  return undefined;
}

export function getScheduleImpace(item: RfiCompiledResponse): string | undefined {
  if(item.ScheduleImpact !== null && item.ScheduleImpact !== undefined)
  {
    return OptionsScheduleImpact.find((op)=>{return item.ScheduleImpact ? op.id === '2' : op.id === '1'})?.name ?? undefined;
  }
  return undefined;
}

export function getCostImpact(item: RfiCompiledResponse): string | undefined {
  return OptionsCostImpact.find((op)=>{return op.id === item.CostImpact})?.name ?? undefined;
}

export function isReopened(item: IPRFIItem): boolean {
  const index = item.rfi_flow.findIndex((f)=>{return f.Status===RFIStatus.Request_to_Reopen && f.DateReceived===item.DateUpdated});
  return index > -1;
}

export function getLatestDueDateItem(rfi: IPRFIListItem): RfiDuedate | undefined {
  if(rfi?.rfi_duedate && rfi.rfi_duedate.length > 0)
  {
    return rfi.rfi_duedate.sort(
      (a, b) =>
        new Date(b.SubmittedDate).getTime() -
        new Date(a.SubmittedDate).getTime(),
    )[0];
  }
  return undefined;
}