/* eslint-disable sonarjs/no-ignored-return */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "lodash";
import { Observable } from "rxjs";
import { EmailService, IPRFIDocumentDownload, IPRFIItem, IUser } from "src/app/api-generated";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import IRFIViewPrepare from "src/app/models/IRFIViewPrepaer";
import RFIRole from "src/app/models/rfiRoles";
import RFIStatus from "src/app/models/rfiStatus";
import LoadingService from "src/app/services/loading.service";
import LocalContractService from "src/app/services/local-contract.service";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import LocalUserService from "src/app/services/local-user.service";
import RouteParams from "src/app/shared/route-params";
import { isReopened } from "src/app/shared/utils";
import AuthService from "../../auth/auth.service";
import RFICompilingStep from "@models/rfiCompilingStep";
import BaseFunctionService from "@services/baseFunction.service";
import RFIDelegate from "@models/rfiDelegate";
import OptionsScheduleImpact from "@models/OptionsScheduleImpact";
import OptionsCostImpact from "@models/OptionsCostImpact";

@Component({
  selector: "app-compiling-rfi",
  templateUrl: "./compiling-rfi.component.html",
  styleUrls: ["./compiling-rfi.component.scss"],
})
export default class CompilingRfiComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  enableSaveDraft = false;

  canIssueRFI = false;

  oldRFI: RFICompilingStep;

  rfi: RFICompilingStep;

  entity: IPRFIItem;

  delegateItem: RFIDelegate;

  rfiDocuments: IPRFIDocumentDownload[];

  users: IUser[];

  contractId: string;

  acknowledge = false;

  defaultTab = "details";

  currentUser: string;

  tempId: string;

  rfiFLowdata:unknown[];

  responseMaxLength = 1500;

  reason: string = '';

  oldReason: string = '';

  public logtab = true;

  ddcManagersList: ISelectItem[] = [];

  delegateList: ISelectItem[] = [];

  srManagerList: ISelectItem[] = [];

  scheduleImpactOptions: ISelectItem[] = [...OptionsScheduleImpact];

  costImpactOptions: ISelectItem[] = [...OptionsCostImpact];

  isCompile = false;

  isPMReview = false;

  isDDCReview = false;

  needDelegate = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activemodal: ModalContainerService,
    public localContractService: LocalContractService,
    private authService: AuthService,
    private localContractUserService: LocalContractUserService,
    private localUserService: LocalUserService,
    public emailService: EmailService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.users = this.localUserService.getItems();
    this.currentUser = this.authService.user.Id;
    const data: IRFIViewPrepare = this.activatedRoute.snapshot.data.RFIPrepare;
    this.entity = data.rfi;
    this.isCompile = this.entity.Status === RFIStatus.Review_Period;
    this.isPMReview = this.entity.Status === RFIStatus.PM_Review;
    this.isDDCReview = this.entity.Status === RFIStatus.DDC_Review;
    this.contractId = this.activatedRoute.snapshot.parent.params[RouteParams.ContractId];
    this.rfiDocuments = data.rfiDocuments;
    this.baseFunctionService.setReviewerWatcherResponseView();
    this.defaultTab = this.entity.rfi_compiled_response?.filter((cr)=>{return cr.IsDraft === false})?.length > 0
        ? "processing"
        : this.baseFunctionService.reviewersResponse.length > 0 ||  this.baseFunctionService.watchersResponse.length > 0
          ? "responses"
          : "details";

    this.oldRFI = data.compile;

    this.ddcManagersList = this.localContractUserService
      .getUsers([RFIRole.DDC_Manager])
      .map((u) => {
        return {
          id: u.UserId,
          name: u.UserInfo.displayName ?? "",
          checked: false,
        };
      });
    this.delegateList = this.ddcManagersList.filter((u)=>{return u.id !== this.entity.DDCManagerId});
    this.srManagerList = this.localContractUserService
      .getUsers([RFIRole.SR_Manager])
      .map((u) => {
        return {
          id: u.UserId,
          name: u.UserInfo.displayName ?? "",
          checked: false,
        };
      });

    this.delegateItem = new RFIDelegate(this.entity);
    if(!this.isCompile && this.oldRFI.DosePMCAccept !== true)
    {
      this.oldReason = this.oldRFI.rfi_compiled_response.Response;
      this.oldRFI.rfi_compiled_response.Response = "";
    }
    this.reason = this.oldReason;
    this.rfi = JSON.parse(JSON.stringify(this.oldRFI));
    this.loadingService.stop();
    if(this.entity.Viewed !== true && isReopened(this.entity))
    {
      this.baseFunctionService.readReopened();
    }
  }

  hasChanges(): boolean {
    if(!this.isCompile && this.rfi.DosePMCAccept !== true)
    {
      return this.oldReason === this.reason;
    }
    const old = JSON.stringify(this.oldRFI);
    const currentRFI = JSON.stringify(this.rfi);

    // true means no changes
    return old !== currentRFI || this.baseFunctionService.areFilesChanged();
  }

  getResponseTitle(): string {
    if(this.isCompile || this.rfi.DosePMCAccept === false)
    {
      return "Compiled Response";
    } else if(this.rfi.ToDDC === false && this.rfi.ToSrManager === false) {
      return "Final Response";
    } else {
      return "Draft Response";
    }
  }

  setAcknowledge(e: boolean): void {
    this.acknowledge = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setResponse(e: string): void {
    this.rfi.rfi_compiled_response.Response = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setScheduleImpact(e: boolean): void {
    this.rfi.rfi_compiled_response.ScheduleImpact = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setCostImpact(e: string): void {
    this.rfi.rfi_compiled_response.CostImpact = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setReason(e: string): void {
    this.reason = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setPMCAccept(e: boolean): void {
    this.rfi.DosePMCAccept = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setSendToDDC(e: boolean): void {
    this.rfi.ToDDC = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setDDCManager(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.rfi.DDCManagerId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setNeedDelegate(e: boolean): void {
    this.needDelegate = e;
  }

  setUserSelect(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.delegateItem.rfi_delegate_detail.UserId = e?.id;
      // this.enableSaveDraft = this.hasChanges();
    }
  }

  setSendToSrManager(e: boolean): void {
    this.rfi.ToSrManager = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setSrManager(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.rfi.SrManagerId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  IsValid(): boolean {
    if(this.needDelegate)
    {
      return this.delegateItem.rfi_delegate_detail.UserId && this.delegateItem.rfi_delegate_detail.UserId!=='';
    } else {
      if(this.isCompile || this.rfi.DosePMCAccept === true) {
        return !(
          !this.rfi.rfi_compiled_response.Response ||
          this.rfi.rfi_compiled_response.Response.trim() === "" ||
          this.rfi.rfi_compiled_response.Response.trim().length > this.responseMaxLength ||
          this.baseFunctionService.isInputInvalid(this.rfi.rfi_compiled_response.Response) ||
          !this.baseFunctionService.isFileNameValid() || !this.acknowledge
        )
      } else {
        return !(
          !this.reason ||
          this.reason.trim() === "" ||
          this.baseFunctionService.isInputInvalid(this.reason) ||
          this.reason.trim().length > this.responseMaxLength
        )
      }
    }
    
  }

  IsTextLengthValid(): boolean {
    return !this.rfi.rfi_compiled_response.Response || this.rfi.rfi_compiled_response.Response.trim().length <= this.responseMaxLength;
  }

  submitRFI(): void {
    this.canIssueRFI = true;
    // console.log("RFI", this.rfi);

    const validation = this.IsValid();
    // console.log(validation);
    if (this.baseFunctionService.hasFilesUploading()) {
      this.baseFunctionService.showFileProgressNotification();
    } else if (validation) {
      const modalInstance = this.activemodal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Confirm Action?";
      modalInstance.instance.body = this.needDelegate 
        ? "The BIC tasks will be reassigned to the person you select below (the new BIC). Once the reassignment is complete, the former BIC will no longer have the ability to carry out any tasks related to this RFI." 
        : !this.rfi.DosePMCAccept 
          ? "The RFI compiled response will be send back to the Coordinator."
          :(this.rfi.ToSrManager || this.rfi.ToDDC || this.isCompile)  
            ? "Once submitted, your task will be completed and you will no longer be able to make updates. The RFI will be moved to the next step in the workflow." 
            :'<span class="warningFont">The official response will be sent to the DB and will not be modifiable. Please verify that all information & attachments are correct.</span>';
      modalInstance.result.then((result) => {
        if (result === 1) {
          this.rfi.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()||!this.baseFunctionService.isFileNameValid()||!this.IsTextLengthValid()) return;
  
    if (this.baseFunctionService.hasFilesUploading()) {
      this.baseFunctionService.showFileProgressNotification();
    } else {
      const modalInstance = this.activemodal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your RFI will save as draft.";
      modalInstance.result.then((result) => {
        if (result === 1) {
          this.rfi.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    if(this.needDelegate)
    {
      this.baseFunctionService.delegate(this.delegateItem);
    } else {
      if(!this.isCompile && this.rfi.DosePMCAccept !== true)
      {
        this.rfi.rfi_compiled_response.Response = this.reason;
        this.rfi.rfi_compiled_response.ScheduleImpact = undefined;
        this.rfi.rfi_compiled_response.CostImpact = undefined;
      }
      this.baseFunctionService.update(this.rfi);
    }
  }

  importFile(e: FileModel[]): void {
    this.baseFunctionService.importFile(e);
    this.enableSaveDraft = this.hasChanges();
  }

  deleteFile(e: FileModel): void {
    this.baseFunctionService.deleteFile(e);
    this.enableSaveDraft = this.hasChanges();
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  addFileToAttachment(prevItem: FileModel): void {
    this.baseFunctionService.addFileToAttachment(prevItem);
    this.enableSaveDraft = this.hasChanges();
  }

  reassign(): void {
    this.baseFunctionService.reassign();
  }
}
